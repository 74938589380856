import { BaseService } from './base.service'
import { Http } from './http.init'

export class SiteManagerService extends BaseService {
  static get entity () {
    return 'site_manager'
  }

  static request (status = { auth: true }) {
    return new Http(status)
  }
}
